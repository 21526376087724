import React, { useState } from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import LayoutPackage from "../components/layoutPackage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Loader from "../components/loader";
const { renderToString } = require("react-dom/server");

const HeroBanner = styled.section`
  background-image: linear-gradient(
      0deg,
      ${({ topGradient }) => topGradient},
      ${({ bottomGradient }) => bottomGradient}
    ),
    url(${({ background }) => background});
  background-size: cover;
  height: 50vh;
  background-attachment: fixed;
  margin-bottom: 50px;
  display: grid;
  align-items: center;
  @media (max-width: 750px) {
    height: 100vh;
  }
  h1 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 100;
    letter-spacing: 3px;
    margin: 0;
  }
  h2 {
    text-align: center;
    color: #ffffff;
    font-weight: 100;
    text-trasnform: uppercase;
    margin: 0;
  }
`;

const CircleSave = styled.div`
  background: ${({ color }) => color};
  &:after,
  &:before {
    background: ${({ color }) => color};
  }
`;

const ListItem = styled.li`
  list-style-type: none;
  font-size: 25px;
  @media(max-width:750px){
    font-size:18px;
  }
  display: inline-block;
  width: 330px;
  line-height: 58px;
  &:before {
    content: url(data:image/svg+xml,${encodeURIComponent(
      renderToString(<FontAwesomeIcon icon={faCheck} />)
    )});
    width: 20px;
    display: block;
    float: left;
    position: absolute;
    margin-left: -31px;
  }
  span{
    display:block;
    float:left; 
    margin-top:3px;
  }
`;

const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  label {
    display: grid;
    justify-items: center;
    text-align: center;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #777777;
  }
  .pretty {
    margin-right: 0;
    font-size: 32px;
  }
  .pretty .state label:before {
    background: #ffffff;
  }
`;

const PackageForm = styled.form`
  background: lightgrey;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  padding: 75px;
  #contact {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 750px) {
    display: block;
    padding: 20px 0;
  }
  h3 {
    span {
      color: red;
    }
  }
  label {
    width: 100%;
    @media (max-width: 750px) {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  .checky {
    width: 100%;
  }
  input[type="text"],
  input[type="email"] {
    padding: 12px 12px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    &:focus {
      outline: 0;
    }
    @media (max-width: 750px) {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  .text-area {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    textarea {
      height: 150px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      border-radius: 20px;
      border: none;
      padding: 12px 12px;
      font-size: 20px;
      border: none;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
    }
  }
  .submitHand {
    grid-column-start: 1;
    grid-column-end: 3;
    button {
      width: 100%;
      margin-top: 25px;
      border: none;
      background: green;
      color: #ffffff;
      padding: 20px;
      font-size: 32px;
      border-radius: 20px;
    }
    &.fail {
      a,
      span {
        display: block;
        text-align: center;
      }
      button {
        background: grey;
      }
    }
  }
`;

const GroupType = styled.h1`
  text-align: center;
  width: 50%;
  margin: 0 auto 150px auto;
  line-height: 52px;
  @media (max-width: 750px) {
    width: 75%;
  }
`;

const ThankYou = styled.div`
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 150px;
  background: grey;
`;

export default ({ data }) => {
  const [formState, setFormState] = React.useState({
    name: "",
    package: `${data.datoCmsPricing.title}`,
    email: "",
    subject: "",
    message: "",
    reasons: {
      weightLoss: false,
      strength: false,
      sport: false,
      other: false,
    },
  });

  console.log("pushAgain");

  const formStateCheck = formState;

  const checkValidationName = () => {
    if (formStateCheck.name === "") {
      setShowMessageName(true);
    } else {
      setShowMessageName(false);
    }
  };

  const checkValidationEmail = () => {
    if (formStateCheck.email === "") {
      setShowMessageEmail(true);
    } else {
      setShowMessageEmail(false);
    }
  };

  const checkValidationSubject = () => {
    if (formStateCheck.subject === "") {
      setShowMessageSubject(true);
    } else {
      setShowMessageSubject(false);
    }
  };

  const checkValidationMessage = () => {
    if (formStateCheck.message === "") {
      setShowMessageMessage(true);
    } else {
      setShowMessageMessage(false);
    }
  };

  const checkValidationSubmit = () => {
    const formStateCheck = formState;

    if (
      formStateCheck.name !== "" &&
      formStateCheck.email !== "" &&
      formStateCheck.subject !== "" &&
      formStateCheck.message !== ""
    ) {
      setFormInvalid(true);
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      const changedReason = e.target.getAttribute("name");
      setFormState({
        ...formState,
        reasons: {
          ...formState.reasons,
          [changedReason]: !formState.reasons[changedReason],
        },
      });
      console.log(formState);
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    checkValidationSubmit();
  };

  const prepareDataForApi = (formData) => {
    const newFormData = Object.assign({}, formData); // optional if passed cloned copy of formData object or you can also use lodash cloneDeep
    newFormData.data = {
      reasonArray: [],
    };

    Object.keys(newFormData.reasons).forEach((key) => {
      if (newFormData.reasons[key]) {
        newFormData.data.reasonArray.push({ reason: key });
      }
    });

    delete newFormData.reasons;

    return newFormData;
  };

  //Form Errors and Hiding and Showing

  const [form, setForm] = useState(true);

  function showForm(e) {
    e.preventDefault();
    setForm(true);
  }

  const [formFail, setFormFail] = useState(true);

  function formFailReset(e) {
    e.preventDefault();
    setFormFail(true);
  }

  const [formInvalid, setFormInvalid] = useState(false);

  // const [nameVal, setNameVal] = useState(false);

  const [showMessageName, setShowMessageName] = useState(false);

  const [showMessageEmail, setShowMessageEmail] = useState(false);

  const [showMessageSubject, setShowMessageSubject] = useState(false);

  const [showMessageMessage, setShowMessageMessage] = useState(false);

  const [loading, setLoading] = useState(true);
  //End

  // Try Catch

  //End

  const submitForm = async (e) => {
    e.preventDefault();

    //All validation
    if (
      formState.name === "" ||
      formState.email === "" ||
      formState.subject === "" ||
      formState.message === ""
    ) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);

      const newFormData = prepareDataForApi(formState);
      setForm(false);
      setLoading(false);

      try {
        const response = await fetch("/.netlify/functions/sendmail", {
          method: "POST",
          body: JSON.stringify(newFormData),
        }).then(() => {
          setLoading(true);
        });

        if (!response.ok) {
          console.log(response);

          setFormFail(false);

          return;
        }

        console.log("success email");
      } catch (e) {
        console.log("error");
      }
    }
  };

  return (
    <>
      {/* {data.datoCmsHome.topGradientColor.hex === null ?
    
    <HeroBanner background={data.datoCmsPricing.coverImage.url} >
      <h1>{data.datoCmsPricing.title}</h1>
      <h2>Package</h2>
    </HeroBanner>
    
    
      :  */}

      <HeroBanner
        background={data.datoCmsPricing.coverImage.url}
        topGradient={data.datoCmsPricing.topGradientColor.hex}
        bottomGradient={data.datoCmsPricing.bottomGradientColor.hex}
      >
        <div>
          <h1>{data.datoCmsPricing.title}</h1>
          <h2>Indivudual Package</h2>
        </div>
      </HeroBanner>

      <GroupType>{data.datoCmsPricing.groupTypeHeader}</GroupType>

      {/*  } */}

      <LayoutPackage>
        <article className="package-single">
          <HelmetDatoCms seo={data.datoCmsPricing.seoMetaTags} />

          {form ? (
            <PackageForm onSubmit={submitForm} autoComplete="off">
              {/* <input type="text" name="package" value={data.datoCmsPricing.title} /> */}
              <label>
                <h3>Name{showMessageName && <span> *Required</span>}</h3>
                <input
                  type="text"
                  name="name"
                  value={formState.name}
                  onChange={onChange}
                  onKeyUp={checkValidationName}
                  autoComplete="off"
                />
              </label>
              <label>
                <h3>Email{showMessageEmail && <span> *Required</span>}</h3>
                <input
                  type="email"
                  name="email"
                  value={formState.email}
                  onChange={onChange}
                  onKeyUp={checkValidationEmail}
                  autoComplete="off"
                />
              </label>
              <label>
                <h3>Subject{showMessageSubject && <span> *Required</span>}</h3>
                <input
                  type="text"
                  name="subject"
                  value={formState.subject}
                  onChange={onChange}
                  onKeyUp={checkValidationSubject}
                />
              </label>
              <div className="checky">
                <h3>Reasons for wanting to train</h3>
                <CheckBoxes>
                  <label>
                    <h4>Weight Loss</h4>
                    <div className="pretty p-smooth p-curve p-fill">
                      <input
                        type="checkbox"
                        name="weightLoss"
                        reason="Weight Loss"
                        checked={formState.reasons.weightLoss}
                        onChange={onChange}
                      />
                      <div className="state p-primary">
                        <label></label>
                      </div>
                    </div>
                  </label>
                  <label>
                    <h4>Strength</h4>
                    <div className="pretty p-smooth p-curve p-fill">
                      <input
                        type="checkbox"
                        name="strength"
                        reason="Strength"
                        checked={formState.reasons.strength}
                        onChange={onChange}
                      />
                      <div className="state p-primary">
                        <label></label>
                      </div>
                    </div>
                  </label>
                  <label>
                    <h4>Sport</h4>
                    <div className="pretty p-smooth p-curve p-fill">
                      <input
                        type="checkbox"
                        name="sport"
                        reason="Sport"
                        checked={formState.reasons.sport}
                        onChange={onChange}
                      />
                      <div className="state p-primary">
                        <label></label>
                      </div>
                    </div>
                  </label>
                  <label>
                    <h4>Other</h4>
                    <div className="pretty p-smooth p-curve p-fill">
                      <input
                        type="checkbox"
                        name="other"
                        reason="Other"
                        checked={formState.reasons.other}
                        onChange={onChange}
                      />
                      <div className="state p-primary">
                        <label></label>
                      </div>
                    </div>
                  </label>
                </CheckBoxes>
              </div>

              <div className="text-area">
                <label>
                  <h3>
                    Message{showMessageMessage && <span> *Required</span>}
                  </h3>
                  <textarea
                    name="message"
                    value={formState.message}
                    onChange={onChange}
                    onKeyUp={checkValidationMessage}
                  />
                </label>
              </div>

              {formFail ? (
                <label
                  className={formInvalid ? `submitHand` : `submitHand fail`}
                >
                  {formInvalid ? (
                    <button type="submit">Submit</button>
                  ) : (
                    <button disabled type="submit">
                      Submit
                    </button>
                  )}
                </label>
              ) : (
                <label className="submitHand fail">
                  <span>Something Went Wrong</span>
                  <a onClick={formFailReset} href="#">
                    Click to reset form
                  </a>
                  <button disabled type="submit">
                    Submit
                  </button>
                </label>
              )}
            </PackageForm>
          ) : (
            <>
              {loading ? (
                <ThankYou>
                  <div>
                    <p>Thank you your message has been sent</p>
                  </div>

                  <a onClick={showForm} href="#">
                    Send Again
                  </a>
                </ThankYou>
              ) : (
                <ThankYou>
                  <Loader />
                </ThankYou>
              )}
            </>
          )}

          <section className="pricing pricing-single">
            <figure
              style={{
                borderColor: data.datoCmsPricing.packageBorderColor.hex,
                boxShadow: `-20px 20px 1px ${data.datoCmsPricing.packageShadowColor.hex}`,
              }}
              className="card"
            >
              {data.datoCmsPricing.packageSavings ? (
                <CircleSave
                  color={data.datoCmsPricing.packageBorderColor.hex}
                  className="circle-save"
                >
                  <p>${data.datoCmsPricing.packageSavings}</p>
                  <p>savings</p>
                </CircleSave>
              ) : null}

              <h6
                style={{
                  backgroundColor: data.datoCmsPricing.packageBorderColor.hex,
                }}
                className="card__title"
              >
                <a>{data.datoCmsPricing.title}</a>
              </h6>

              <p
                style={{ color: data.datoCmsPricing.packageBorderColor.hex }}
                className="price-session"
              >
                <span>${data.datoCmsPricing.priceAmount}</span> |{" "}
                <span>{data.datoCmsPricing.lengthOfSession}</span>
              </p>

              <p>{data.datoCmsPricing.numberOfSessions}</p>

              <ul className="details-list">
                {data.datoCmsPricing.details.map((detailEntry) => {
                  return (
                    <ListItem key={detailEntry.id}>
                      {" "}
                      {detailEntry.task}
                    </ListItem>
                  );
                })}
              </ul>

              {/* <ul className="details-list">{pricing.details.map(detailEntry => { 
            return <ListItem key={detailEntry.id}><span>{detailEntry.task}</span></ListItem>
            
          })}</ul> */}

              {data.datoCmsPricing.totalPrice && (
                <p>Total Price $ {data.datoCmsPricing.totalPrice}</p>
              )}
            </figure>
          </section>

          {/* <section className="right-package-details">
  
            <img src={data.datoCmsPricing.coverImage.url} />
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: data.datoCmsPricing.descriptionNode.childMarkdownRemark.html,
              }}
            />
           
              
        </section>
        */}
        </article>
      </LayoutPackage>
    </>
  );
};

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsPricing(slug: { eq: $slug }) {
      totalPrice
      packageBorderColor {
        hex
      }
      packageShadowColor {
        hex
      }
      groupTypeHeader
      promoWide
      packageSavings
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      priceAmount
      details {
        task
      }
      lengthOfSession
      numberOfSessions
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        url
      }
      topGradientColor {
        hex
      }
      bottomGradientColor {
        hex
      }
      packageBorderColor {
        hex
      }
    }
    datoCmsHome {
      topGradientColor {
        hex
      }
      bottomGradientColor {
        hex
      }
    }
  }
`;
